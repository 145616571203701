import { MouseEvent, useEffect, useMemo, useRef, useState } from "react";
import { format } from "date-fns";
import {
    Box,
    Text,
    Divider,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Flex,
    useDisclosure,
    Link,
} from "@chakra-ui/react";

import DeleteDialog from "../../components/DeleteDialog";

import { CancelSubscription, PaymentTransaction, SubscriptionEvent, SubscriptionPlatform, SubscriptionStatus } from "../redux/types";
import SubscriptionEvents from "./SubscriptionEvents";
import { LoginType, PodmeColor } from "../../redux/types";
import LoginMethodLabel from "../../components/LoginMethodLabel/LoginMethodLabel";
import { expand, pulsate } from "../../styles/animations";
import PaymentTransactions from "./PaymentTransactions";

interface Props {
    name: string | undefined;
    highlightElement: boolean;
    hideElement?: boolean;
    accountLoginType: LoginType;
    status: SubscriptionStatus;
    platform: SubscriptionPlatform;
    startDate: Date;
    endDate: Date | null;
    subscriptionEvents?: SubscriptionEvent[] | null;
    paymentTransactions?: PaymentTransaction[] | null;
    lastOrderId?: string;
    isActive?: boolean;
    bundlerPartnerName?: string;
    rewardMonthCredit?: number;
    convertsToStudentSubscriptionOnNextRenewal?: boolean;
    userAccountId: string;
    subscriptionId: number;
    onSelect: () => void;
    cancelSubscription?: (podcastId?: string) => CancelSubscription;
}

function SubscriptionItem({
    name,
    highlightElement,
    hideElement = false,
    onSelect,
    accountLoginType,
    status,
    platform,
    startDate,
    endDate,
    subscriptionEvents,
    paymentTransactions,
    lastOrderId,
    cancelSubscription,
    isActive = false,
    bundlerPartnerName,
    rewardMonthCredit,
    convertsToStudentSubscriptionOnNextRenewal = false,
    userAccountId,
    subscriptionId,
}: Props) {
    const { onClose } = useDisclosure();
    const googleLink = "https://play.google.com/console/u/1/developers/8823990186617963500/orders/";

    const accordionItemRef = useRef<HTMLDivElement>(null);

    const groupedEvents = useMemo(() => groupEventsByYear(subscriptionEvents ?? []), [subscriptionEvents]);
    const groupedPaymentTransactions = useMemo(() => groupTransactionByYear(paymentTransactions ?? []), [paymentTransactions]);

    const handleAccountClick = (e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => {
        e.stopPropagation();
        onSelect();
    };

    useEffect(() => {
        if (highlightElement && accordionItemRef.current) {
            accordionItemRef.current.scrollIntoView({ block: "start", behavior: "smooth", inline: "start" });
        }
    }, [highlightElement]);

    return (
        <>
            <AccordionItem
                ref={accordionItemRef}
                scrollMarginTop="1rem"
                padding="0"
                borderWidth="1px"
                borderStyle="solid"
                borderColor={highlightElement ? "#e4d652" : "#e0e0e0"}
                rounded="1rem"
                animation={!hideElement && !isActive ? `${expand} 250ms forwards ease-out` : undefined}
                bgColor="#eee"
                _hover={{
                    bgColor: "#eaeaea",
                }}
            >
                <AccordionButton
                    padding="1rem"
                    rounded="1rem"
                    display="flex"
                    gridGap="1rem"
                    justifyContent="space-between"
                    animation={highlightElement ? `${pulsate} 500ms infinite` : undefined}
                    _focus={{ outline: "none" }}
                    _hover={{
                        bgColor: "none",
                    }}
                >
                    <Flex flex="1" minWidth="fit-content" direction="column" alignItems="flex-start" gridGap="0.25rem">
                        <Box padding="0.15rem 1rem" bgColor={getStateColor(status)} rounded="3rem">
                            <Text as="span" display="block" fontSize="0.875rem">
                                {SubscriptionStatus[status]}
                            </Text>
                        </Box>
                        {Boolean(rewardMonthCredit) && (
                            <Box padding="0.15rem 1rem" bgColor={getStateColor(status)} rounded="3rem">
                                <Text as="span" display="block" fontSize="0.875rem">
                                    {getRewardMonthCredit(rewardMonthCredit)}
                                </Text>
                            </Box>
                        )}
                        {Boolean(convertsToStudentSubscriptionOnNextRenewal) && (
                            <Box padding="0.15rem 1rem" bgColor={PodmeColor.Warning} rounded="3rem">
                                <Text as="span" display="block" fontSize="0.875rem">
                                    Converts to student subscription on next renewal
                                </Text>
                            </Box>
                        )}
                    </Flex>

                    <Flex flex="3" direction="column" alignItems="flex-start" gridGap="0.25rem">
                        <Text as="span" fontWeight="900" fontSize="0.75rem" color={"#999"} textTransform="uppercase">
                            Name
                        </Text>
                        <Text fontWeight={600} textAlign="left" style={{ textWrap: "balance" } as any}>
                            {getSubscriptionPlanName(name, bundlerPartnerName)}
                        </Text>
                    </Flex>

                    <Flex flex="2" direction="column" alignItems="flex-start" gridGap="0.25rem">
                        <Text as="span" fontWeight="900" fontSize="0.75rem" color={"#999"} textTransform="uppercase">
                            Platform
                        </Text>
                        <Text as="span" fontSize="1rem" fontWeight={600}>
                            {SubscriptionPlatform[platform]}
                        </Text>
                    </Flex>

                    <Flex flex="2" direction="column" alignItems="flex-start" gridGap="0.25rem">
                        <Text as="span" fontWeight="900" fontSize="0.75rem" color={"#999"} textTransform="uppercase">
                            Account
                        </Text>
                        <Box
                            onClick={handleAccountClick}
                            padding="0"
                            background="none"
                            height="auto"
                            _hover={{
                                boxShadow: "none",
                            }}
                            _focus={{
                                outline: "none",
                                boxShadow: "none",
                            }}
                        >
                            <Box
                                color="#256199"
                                cursor="pointer"
                                _hover={{
                                    textDecoration: "underline",
                                }}
                            >
                                <LoginMethodLabel loginType={accountLoginType} />
                            </Box>
                        </Box>
                    </Flex>

                    <AccordionIcon />
                </AccordionButton>
                <AccordionPanel>
                    <Divider borderColor="#ccc" />
                    <Flex pt={4} justifyContent="space-between" flexDirection="column" gridGap="2rem">
                        <Flex flexDirection="column" gridGap={4}>
                            <Flex gridGap="1rem">
                                <Flex flex="2" direction="column" gridGap="0.25rem">
                                    <Text fontWeight="900" fontSize="0.75rem" color={"#999"} textTransform="uppercase">
                                        Start date
                                    </Text>
                                    <Text fontWeight={600}>{format(new Date(startDate), "yyyy-MM-dd HH:mm")}</Text>
                                </Flex>
                                <Flex flex="3" direction="column" gridGap="0.25rem">
                                    <Text fontWeight="900" fontSize="0.75rem" color={"#999"} textTransform="uppercase">
                                        {isActive && status !== SubscriptionStatus.Cancelled ? "Renewal date" : "End date"}
                                    </Text>
                                    <Text as="span" fontWeight={600}>
                                        {endDate ? format(new Date(endDate), "yyyy-MM-dd HH:mm") : "-"}
                                    </Text>
                                </Flex>
                            </Flex>
                            {platform === 2 && (
                                <Flex direction="column" gridGap="0.25rem">
                                    <Text as="span" fontWeight="900" fontSize="0.75rem" color={"#999"} textTransform="uppercase">
                                        Google order id (latest):
                                    </Text>
                                    <Link
                                        style={{ display: "flex", maxWidth: "fit-content" }}
                                        href={`${googleLink}${lastOrderId}`}
                                        isExternal
                                        display="block"
                                        color={PodmeColor.LicoriceBlue}
                                        fontSize="1.25em"
                                        fontWeight="400"
                                    >
                                        <Text as="span" fontSize="1rem" fontWeight={600}>
                                            {lastOrderId}
                                        </Text>
                                    </Link>
                                </Flex>
                            )}
                        </Flex>
                        <SubscriptionEvents groupedEvents={groupedEvents} />
                        <PaymentTransactions
                            groupedPaymentTransactions={groupedPaymentTransactions}
                            userAccountId={userAccountId}
                            subscriptionId={subscriptionId}
                        />
                    </Flex>
                    {isActive && status !== SubscriptionStatus.Cancelled && platform === SubscriptionPlatform.Adyen && (
                        <Box pt={8} display="flex" justifyContent="flex-end">
                            <DeleteDialog
                                buttonLabel="Cancel subscription"
                                title="Cancel subscription?"
                                text="Are you sure you want to cancel subscription?"
                                deleteAction={() => {
                                    cancelSubscription && cancelSubscription();
                                    onClose();
                                }}
                                buttonVariant="solid"
                                buttonVariantColor="red"
                                buttonSize="sm"
                            />
                        </Box>
                    )}
                </AccordionPanel>
            </AccordionItem>
        </>
    );
}

function groupEventsByYear(events: SubscriptionEvent[] | null): { year: string; events: SubscriptionEvent[] }[] {
    if (!events) return [];

    const map = new Map<string, SubscriptionEvent[]>();

    events.forEach((e) => {
        const key = new Date(e.date).getUTCFullYear().toString();
        const collection = map.get(key);

        if (collection) {
            collection.push(e);
        } else {
            map.set(key, [e]);
        }
    });

    const result = Array.from(map)
        .map(([year, eventsInner]) => ({ year, events: eventsInner }))
        .sort((a, b) => (a.year > b.year ? -1 : 1));

    return result;
}

function groupTransactionByYear(transactions: PaymentTransaction[] | null): { year: string; transactions: PaymentTransaction[] }[] {
    if (!transactions) return [];

    const map = new Map<string, PaymentTransaction[]>();

    transactions.forEach((e) => {
        const key = new Date(e.createdAt).getUTCFullYear().toString();
        const collection = map.get(key);

        if (collection) {
            collection.push(e);
        } else {
            map.set(key, [e]);
        }
    });

    const result = Array.from(map)
        .map(([year, transactionsInner]) => ({ year, transactions: transactionsInner }))
        .sort((a, b) => (a.year > b.year ? -1 : 1));

    return result;
}

function getSubscriptionPlanName(name?: string, bundlerPartnerName?: string) {
    if (!bundlerPartnerName) return name;

    return name + " (" + bundlerPartnerName + ")";
}

function getStateColor(state: SubscriptionStatus) {
    switch (state) {
        case SubscriptionStatus.Active:
            return "green.300";
        case SubscriptionStatus.Cancelled:
            return "yellow.400";
        case SubscriptionStatus.Error:
            return "red.500";
        case SubscriptionStatus.FailedToWithdrawMoney:
            return "orange.400";
        case SubscriptionStatus.TestPeriod:
            return "orange.400";
        case SubscriptionStatus.Inactive:
            return "gray.400";
        case SubscriptionStatus.PaymentProblem:
            return "red.400";
    }
}

function getRewardMonthCredit(rewardMonthCredit?: number) {
    if (!rewardMonthCredit) return "";
    return `${rewardMonthCredit} reward month${rewardMonthCredit > 1 ? "s" : ""} left`;
}

export default SubscriptionItem;
function dispatch(arg0: any) {
    throw new Error("Function not implemented.");
}
